import { propertyOf } from "../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../RAFComponents/models/Common/RAFEntityBase";
import { ILookupRow } from "../../../RAFComponents/models/CompositeTypes/ILookupRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";

export class IChecklistItemTransRow {
    UID?: string;
    Title?: string;
    Description?: string;
    Status?: string;
    DocumentStatus?: string;
    IsRequired?: boolean;
    IsCompliance?: boolean;
    RequiresApproval?: boolean;
    AllowUserUpload?: boolean;
    DeadlineUnits?: string;
    DeadlineInterval?: number;
    DeadlineCalculation?: string;
    DueDate?: Date;
    EntityUID?: string;
    Entity?: string;
    IssueDate?: Date;
    HasExpiryDate?: boolean;
    HasValidity?: boolean;
    ExpiryDate?: Date;
    UploadDate?: Date;
    ReviewDate?: Date;
    ValidityPeriod?: Date;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    DocumentUID?: string;
    Document?: string;
    ChecklistTemplateUID?: string;
    ChecklistTemplate?: string;
    ChecklistTemplateItemUID?: string;
    ChecklistTemplateItem?: string;
    ChecklistItemTransId?: string;
    VersionNumber?: number;
    IsActive?: boolean;
    CategoryUID?: string;
    Category?: string;

    Portal?: string;
    PortalUID?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class ChecklistItemTransRow extends IChecklistItemTransRow implements RAFEntityBase, ILookupRow {
    getClassName?() {
        return CareEsioEntity.ChecklistItemTrans.EntityName;
    }
    getIdField?(): string {
        return propertyOf<ChecklistItemTransRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "ChecklistItemTrans/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "ChecklistItemTrans/Save";
    }
    getGroupByUrl?(): string {
        return "ChecklistItemTrans/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "ChecklistItemTrans/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
