import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import {
  ConvertSystemName,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { BusinessProcessRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../../RAFMaster/helpers/RMutils";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { Constants, ContentType } from "../../../../constants/Common/Constants";
import { EmployeeRow } from "../EmployeeRow";

class CareTeamAvailabilityRequest {
  ClientUID: string;
  StartDate: Date;
  EndDate: Date;
  AvailableOnly: boolean;
}

class RetrieveEmployeeByUserRequest {
  UserUID?: string;
  EntityUID?: string;
  EntityName?: string;
}

export class EmployeeAvailableResponseRow {
  StartDate?: Date;
  EndDate?: Date;
  EmployeeUID?: string;
  EmployeeName?: string;
  AvailabilityType?: string;
}

export class EmployeeAvailableDisplayResponseRow {
  EmployeeUID?: string;
  EmployeeName?: string;
  EmployeeInfo?: { StartDate: Date, EndDate: Date, AvailabilityType: string; }[];
}

export const saveEmployeeAPI = (
  employeeRow?: EmployeeRow,
  moduleName?: string
) => {
  return new Promise<{ entityId: string; objectName: string; }>(
    async (resolve) => {
      let saveRequestData = getSaveRequest(employeeRow, employeeRow.UID);
      if (isNotNullAndUndefined(moduleName)) {
        saveRequestData.EntityName = moduleName;
      }
      if (isNotNullAndUndefined(employeeRow)) {
        repositoryActions
          .postDataAndGetResponse(
            "Employee/SaveForm",
            saveRequestData,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              resolve({
                entityId: response.data.EntityId,
                objectName: response.data.ObjectName,
              });
            } else {
              resolve({ entityId: null, objectName: null });
            }
          })
          .catch((error) => resolve({ entityId: null, objectName: null }));
      } else {
        resolve({ entityId: null, objectName: null });
      }
    }
  );
};

export const getRelatedBusinessProcess = (relatedToUID: string) => {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(relatedToUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("SecondaryRelatedToUID");
  relatedFilter.Rules.push(filter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_processes";

  return new Promise<BusinessProcessRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          let businessProcessRows: BusinessProcessRow[] =
            response.data.Entities;
          if (isNotEmptyArray(businessProcessRows)) {
            resolve(businessProcessRows[0]);
          } else {
            resolve(null);
          }
        }
      })
      .catch((error) => error);
  });
};

export const getCurrentEmployeeId = () => {
  return new Promise<EmployeeRow>(async (resolve) => {
    repositoryActions
      .postDataAndGetResponse(
        "Employee/CurrentEmployee",
        null,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          response.data &&
          response.data.ResultTable &&
          response.data.ResultTable.length > 0
        ) {
          let employeeRow = response.data.ResultTable[0];
          resolve(employeeRow);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const retrieveEmployeeByUserUID = (userUID: string) => {
  return new Promise<EmployeeRow>(async (resolve) => {
    if (isNotNullAndUndefined(userUID)) {
      const entityRow: EntityRow = await getEntityByObjectName(
        {
          ObjectName: ConvertSystemName(CareEsioEntity.Employee.EntityName),
        }
      );
      if (isNotNullAndUndefined(entityRow)) {
        const retrieveEmployeeByUserRequest = new RetrieveEmployeeByUserRequest();
        retrieveEmployeeByUserRequest.UserUID = userUID;
        retrieveEmployeeByUserRequest.EntityUID = entityRow.UID;

        repositoryActions
          .postDataAndGetResponse(
            "Employee/RetrieveFormByUser",
            retrieveEmployeeByUserRequest,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              let employeeRow = response.data.ResultTable[0];
              resolve(employeeRow);
            } else {
              resolve(null);
            }
          })
          .catch((error) => resolve(null));
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

export const linkEmployeeToESIO = (employeeId: string) => {
  return new Promise<any>(async (resolve) => {
    if (isNotNullAndUndefined(employeeId)) {
      const url = Constants.baseRAFXeroUrl + "api/" + "Employee/LinkEmployeeToESIO";
      const getRequest = { EntityId: employeeId };
      let progressDiv = showProgress('body');
      repositoryActions
        .postDataAndGetResponse(
          url,
          getRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (response.data) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getEmployeeAvailability = (
  startDate: Date,
  endDate: Date
) => {
  return new Promise<EmployeeAvailableResponseRow[]>(async (resolve) => {
    if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
      repositoryActions
        .postDataAndGetResponse(
          "Employee/Availability",
          { StartDate: startDate, EndDate: endDate },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getEmployeeCareTeamAvailability = (
  clientUID: string,
  startDate: Date,
  endDate: Date,
  availableOnly: boolean
) => {
  return new Promise<EmployeeAvailableResponseRow[]>(async (resolve) => {
    const careTeamAvailabilityRequest = new CareTeamAvailabilityRequest();
    careTeamAvailabilityRequest.ClientUID = clientUID;
    careTeamAvailabilityRequest.StartDate = startDate;
    careTeamAvailabilityRequest.EndDate = endDate;
    if (isNotNullAndUndefined(availableOnly)) {
      careTeamAvailabilityRequest.AvailableOnly = availableOnly;
    }

    repositoryActions
      .postDataAndGetResponse(
        "Employee/CareTeamAvailability",
        careTeamAvailabilityRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotEmptyArray(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const SyncAllEmployeeToXero = () => {
  return new Promise<boolean>((resolve) => {
    const url =
      Constants.baseRAFXeroUrl +
      "api/" +
      "Employee/SyncAllEmployeeToXero";
    repositoryActions
      .postDataAndGetResponse(
        url,
        {},
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(true);
        } else {
          showWarningToast("Sync not successful!");
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};