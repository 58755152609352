import { IsNotNullOrWhiteSpace, getSaveRequest, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../constants/Common/Constants";
import { ChecklistTemplateItemRow } from "./ChecklistTemplateItemRow";


export const saveChecklistTemplateItemAPI = (checklistTemplateItemRow: ChecklistTemplateItemRow, url: string) => {
    return new Promise<{ entityId: string, objectName: string; }>(async (resolve) => {
        if (isNotNullAndUndefined(checklistTemplateItemRow)) {
            const URL = IsNotNullOrWhiteSpace(url) ? url : "ChecklistTemplateItem/Save";
            repositoryActions
                .postDataAndGetResponse(
                    URL,
                    getSaveRequest(checklistTemplateItemRow, checklistTemplateItemRow.UID),
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.EntityId)
                    ) {
                        resolve({ entityId: response.data.EntityId, objectName: response.data.ObjectName });
                    } else {
                        resolve({ entityId: null, objectName: null });
                    }
                })
                .catch((error) => resolve({ entityId: null, objectName: null }));
        } else {
            resolve({ entityId: null, objectName: null });
        }
    });
}; 