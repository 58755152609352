import { DialogUtility } from "@syncfusion/ej2-popups";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IDialogProps,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getAllEntities } from "../../../RAFMaster/helpers/RMutils";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { saveChecklistTemplateAPI } from "./ChecklistTemplateHelper";
import { ChecklistTemplateRow } from "./ChecklistTemplateRow";

interface IProps {
  initialValues?: ChecklistTemplateRow;
  onDelete?: () => void;
  objectUID?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  checklistTemplateRow: ChecklistTemplateRow;
  allClientAndEmployeeEntities: EntityRow[];
}

function ManageChecklistTemplate({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = CareEsioEntity.ChecklistTemplate.EntityName;

  const outerDivId = `manageupdate_checklistTemplate_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      allClientAndEmployeeEntities: null,
      checklistTemplateRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [checklistTemplateRow, allEntities] = await Promise.all([
        getIntitailChecklistTemplateRow(),
        getAllEntities(),
      ]);

      const allClientAndEmployeeEntities = allEntities.filter(
        (entity) =>
          entity.EntityName === CareEsioEntity.CareRecipient.EntityName ||
          entity.EntityName === RAFEntityName.Employee
      );
      if (isNotNullAndUndefined(checklistTemplateRow)) {
        setState({
          isLoading: false,
          noContent: false,
          checklistTemplateRow,
          allClientAndEmployeeEntities,
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailChecklistTemplateRow = () => {
    return new Promise<ChecklistTemplateRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const checklistTemplateRow: ChecklistTemplateRow = await RetrieveRecord(
          props.objectUID,
          moduleName
        );
        if (
          isNotNullAndUndefined(checklistTemplateRow) &&
          isNotNullAndUndefined(checklistTemplateRow.UID)
        ) {
          checklistTemplateRow.SelectEntityUID = checklistTemplateRow.EntityUID;
          resolve(checklistTemplateRow);
        } else {
          resolve(null);
        }
      } else {
        if (props.initialValues) {
          resolve(props.initialValues);
        } else {
          let initialObject = new ChecklistTemplateRow();
          initialObject.Status = "Active";
          resolve(initialObject);
        }
      }
    });
  };

  const onSubmitChecklistTemplate = async (formValue: ChecklistTemplateRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const value = R.clone(formValue);
    delete value.SelectEntityUID;
    const response = await saveChecklistTemplateAPI(value);
    hideProgress(progressDiv);
    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(response.entityId);
      }
    } else {
      showWarningToast(
        "Apologies, we're unable to save the record at the moment. Please try again later.!"
      );
    }
  };

  //delete item start
  const onClickDelete = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ChecklistTemplate`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    let isDeleted = await DeleteRecord(props.objectUID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast(RAFActionMessage.RecordNotDeleted);
    }
  }
  //delete item end

  const onChangeEntity = (label, value) => {
    const { allClientAndEmployeeEntities } = state;
    if (isNotNullAndUndefined(value)) {
      const selectedItem = allClientAndEmployeeEntities.find(
        (item) => item.UID === value
      );

      setFormValue(
        rafForm,
        propertyOf<ChecklistTemplateRow>("Entity"),
        selectedItem.DisplayName
      );
      setFormValue(
        rafForm,
        propertyOf<ChecklistTemplateRow>("EntityUID"),
        value
      );
      setFormValue(
        rafForm,
        propertyOf<ChecklistTemplateRow>("EntityType"),
        selectedItem.EntityName
      );
    } else {
      setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("Entity"), null);
      setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("EntityType"), null);
      setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("EntityUID"), null);
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFForm
                type={ChecklistTemplateRow}
                initialValues={state.checklistTemplateRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitChecklistTemplate}
                className="h-100"
              >
                <div className="e-dlg-content-outer" id={outerDivId}>
                  <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                      <div className="col-md-12">
                        <ACTextBox
                          field={propertyOf<ChecklistTemplateRow>("Title")}
                          formGroupClassName="mb-0"
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <RAFHtmlEditor
                          field={propertyOf<ChecklistTemplateRow>(
                            "Description"
                          )}
                          label="Description"
                          showLabel={true}
                          placeholder="Description"
                          rows={5}
                          fullHeight={false}
                          rowClassName="row g-0 gy-2"
                          useMentions={false}
                          mentionsField={null}
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <div className="col-md-12">
                        <ACDropdown
                          field={propertyOf<ChecklistTemplateRow>("Status")}
                          label="Status"
                          moduleName={moduleName}
                          showLabel={true}
                          allowAdd={false}
                          required
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <div className="col-md-12">
                        <RAFDropdownCC
                          field={propertyOf<ChecklistTemplateRow>(
                            "SelectEntityUID"
                          )}
                          label="Module"
                          showClearButton={false}
                          placeholder="Module"
                          required
                          showLabel
                          allowFiltering
                          onChanged={onChangeEntity}
                          formGroupClassName="mb-0"
                        >
                          {isNotEmptyArray(
                            state.allClientAndEmployeeEntities
                          ) &&
                            state.allClientAndEmployeeEntities.map((item) => {
                              return (
                                <RAFChoiceOption
                                  key={item.UID}
                                  label={item.DisplayName}
                                  value={item.UID}
                                />
                              );
                            })}
                        </RAFDropdownCC>
                      </div>
                    </div>
                  </div>
                  <div className="e-dlg-footerContent ">
                    <div className="w-100">
                      <div className="row gx-2">
                        {isNotNullAndUndefined(props.objectUID) && (
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Delete}
                              onClick={() => onClickDelete()}
                              idString="DeleteContent"
                              className="e-danger e-outline"
                            />
                          </div>
                        )}
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="CreateContent"
                            disabled={rafForm && rafForm.submitting}
                          />
                        </div>
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageChecklistTemplate);
