import { getSaveRequest, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";
import { ChecklistTemplateRow } from "./ChecklistTemplateRow";

export const saveChecklistTemplateAPI = (checklistTemplateRow: ChecklistTemplateRow) => {
    return new Promise<{ entityId: string, objectName: string; }>(async (resolve) => {
        if (isNotNullAndUndefined(checklistTemplateRow)) {
            repositoryActions
                .postDataAndGetResponse(
                    "checklistTemplate/Save",
                    getSaveRequest(checklistTemplateRow, checklistTemplateRow.UID),
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.EntityId)
                    ) {
                        resolve({ entityId: response.data.EntityId, objectName: response.data.ObjectName });
                    } else {
                        resolve({ entityId: null, objectName: null });
                    }
                })
                .catch((error) => resolve({ entityId: null, objectName: null }));
        } else {
            resolve({ entityId: null, objectName: null });
        }
    });
}; 