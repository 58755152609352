import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import moment from "moment";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { getFormValue, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  IsSuperAdmin,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  hexToRGBA,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  MomentFormats,
  RAFButtonConstant,
  RAFLayout,
  RAFStatusNameWithColor
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import CreateActivitySummary from "../../ActiveContacts/Activity/Controller/CreateActivitySummary";
import { ContentLibraryRow } from "../../ActiveContacts/Document/Library/ContentLibraryRow";
import { downloadDocumentFile } from "../../ActiveContacts/Document/Library/DocumentHelper";
import DocumentLeftCardContent from "../../ActiveContacts/Document/Library/DocumentLeftCardContent";
import {
  RAFChecklistItemTransStatus,
  saveChecklistItemTransAPI,
} from "./ChecklistItemTransHelper";
import ChecklistItemTransManageUploadDocumentFile from "./ChecklistItemTransManageUploadDocumentFile";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";
import ManageChecklistTemplateTrans from "./ManageChecklistTemplateTrans";

interface IProps {
  objectUID: string;
  onSaveChecklistItemTrans: () => void;
  onDeleteChecklistItemTrans: () => void;
  uploadDocumentPermissionName: string;
  deleteDocumentPermissionName: string;
  permissionNameEditDocumentProperties: string;

  permissionNameEditDocumentExpiryDate: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  showUploadContent: boolean;
  checklistItemTransRow: ChecklistItemTransRow;
  contentLibraryRow: ContentLibraryRow;
  activitySummaryKey: number;

  showChecklistItemTransEditDlgContent: boolean;
}

function ChecklistItemTransDetailsContent({
  ...props
}: PropsWithChildren<IProps>) {
  const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;
  let deleteDialog: any;
  let rafFormRef: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      checklistItemTransRow: null,
      contentLibraryRow: null,
      showUploadContent: false,
      activitySummaryKey: Math.random(),
      showChecklistItemTransEditDlgContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.objectUID]);

  const refresh = async () => {
    setState({ isLoading: true, noContent: true });
    const checklistItemTransRow = await RetrieveRecord(props.objectUID, moduleName);

    if (
      isNotNullAndUndefined(checklistItemTransRow) &&
      isNotNullAndUndefined(checklistItemTransRow.UID)
    ) {
      const contentLibraryRow = await RetrieveRecord(
        checklistItemTransRow.DocumentUID,
        RAFEntityName.ContentLibrary
      );

      setState({
        isLoading: false,
        noContent: false,
        checklistItemTransRow,
        contentLibraryRow,
      });
    } else {
      setState({ isLoading: false, noContent: true });
    }
  };

  const refreshOnUpdate = async () => {
    setState({ isLoading: true, noContent: true, showChecklistItemTransEditDlgContent: false, showUploadContent: false });

    const checklistItemTransRow = await RetrieveRecord(
      props.objectUID,
      moduleName
    );

    if (
      isNotNullAndUndefined(checklistItemTransRow) &&
      isNotNullAndUndefined(checklistItemTransRow.UID)
    ) {
      const contentLibraryRow = await RetrieveRecord(
        checklistItemTransRow.DocumentUID,
        RAFEntityName.ContentLibrary
      );

      setState({
        isLoading: false,
        noContent: false,
        checklistItemTransRow,
        contentLibraryRow,
      });
    } else {
      setState({ isLoading: false, noContent: true });
    }
  };

  const getStatusDiv = (
    checklistItemTransItem: ChecklistItemTransRow,
    uistyle?: "Badge" | "DotBadge"
  ) => {
    const status = checklistItemTransItem.Status;

    const colorCodeName = isNotNullAndUndefined(status)
      ? RAFStatusNameWithColor[status]
      : null;
    if (uistyle === "DotBadge") {
      return (
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle
                cx="4"
                cy="4"
                r="4"
                fill={
                  isNotNullAndUndefined(colorCodeName)
                    ? hexToRGBA(colorCodeName.Color, 0.4)
                    : ""
                }
              />
              <circle
                cx="4"
                cy="4"
                r="2"
                fill={
                  isNotNullAndUndefined(colorCodeName)
                    ? colorCodeName.Color
                    : ""
                }
              />
            </svg>
          </div>
          <div className="col-auto body_2">
            <span
              className="body_2"
              style={{
                color: isNotNullAndUndefined(colorCodeName)
                  ? colorCodeName.Color
                  : "",
              }}
            >
              {isNotNullAndUndefined(colorCodeName)
                ? colorCodeName.DisplayName
                : isNotNullAndUndefined(status)
                  ? status
                  : "Status"}
            </span>
          </div>
        </div>
      );
    }
    return (
      <span
        className="raf_badge"
        style={{
          backgroundColor: isNotNullAndUndefined(colorCodeName)
            ? hexToRGBA(colorCodeName.Color, 0.1)
            : hexToRGBA("#333", 0.1),
          color: `${isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333"
            }`,
          border: `1px solid ${hexToRGBA(
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333",
            0.1
          )}`,
        }}
      >
        {isNotNullAndUndefined(colorCodeName)
          ? colorCodeName.DisplayName
          : isNotNullAndUndefined(status)
            ? status
            : "Status"}
      </span>
    );
  };

  const onClickApproveRejectBtn = async (
    action:
      | RAFChecklistItemTransStatus.Rejected
      | RAFChecklistItemTransStatus.Approved
  ) => {
    let progressDiv = showProgress(
      "#checklistItemTransDetailsContent_outerDiv"
    );
    const { checklistItemTransRow } = state;
    const submitChecklistItemTransFormValue = new ChecklistItemTransRow();
    submitChecklistItemTransFormValue.UID = checklistItemTransRow.UID;
    submitChecklistItemTransFormValue.Status = action;

    const checklistItemTransResponse = await saveChecklistItemTransAPI(
      submitChecklistItemTransFormValue
    );
    hideProgress(progressDiv);
    refreshOnUpdate();
    if (props.onSaveChecklistItemTrans) {
      props.onSaveChecklistItemTrans();
    }
  };

  //upload new document start

  const onClickUploadItem = () => {
    setState({ showUploadContent: true });
  };

  const uploadAndLinkDocumentContent = () => {
    if (state.showUploadContent) {
      const { checklistItemTransRow } = state;
      return (
        <ChecklistItemTransManageUploadDocumentFile
          onSave={() => onUploadDocumentFile()}
          onClose={() => uploadDialogClose()}
          relatedToUID={checklistItemTransRow.UID}
          relatedTo={checklistItemTransRow.Title}
          relatedEntity={moduleName}
          relatedToType={moduleName}
          selectedDocumentUID={isNotNullAndUndefined(state.contentLibraryRow) ? state.contentLibraryRow.UID : null}
          createPermissionName={props.uploadDocumentPermissionName}
          updatePermissionName={props.uploadDocumentPermissionName}
          mode={
            isNotNullAndUndefined(state.contentLibraryRow)
              ? "updateVersion"
              : "create"
          }
          hasExpiryDateRequired={checklistItemTransRow.HasExpiryDate}
          deadlineInterval={checklistItemTransRow.DeadlineInterval}
          deadlineUnits={checklistItemTransRow.DeadlineUnits}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onUploadDocumentFile = async () => {
    setState({ showUploadContent: false });
    if (props.onDeleteChecklistItemTrans) {
      props.onDeleteChecklistItemTrans();
    }
  };

  const uploadDialogClose = () => {
    setState({ showUploadContent: false });
  };
  //upload new document end

  const getApproveRejectContent = () => {
    if (
      state.checklistItemTransRow.RequiresApproval &&
      state.checklistItemTransRow.Status ===
      // RAFChecklistItemTransStatus.Uploaded
      RAFChecklistItemTransStatus.Pending
    ) {
      return (
        <>
          <div className="col-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Reject}
              onClick={() =>
                onClickApproveRejectBtn(RAFChecklistItemTransStatus.Rejected)
              }
              className="btn_state_danger semi_dark new_style raf_sm"
              iconCss={RAFButtonConstant.Clear.IconCss}
              iconBtn
            />
          </div>
          <div className="col-auto ms-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Approve}
              onClick={() =>
                onClickApproveRejectBtn(RAFChecklistItemTransStatus.Approved)
              }
              className="btn_state_success semi_dark new_style raf_sm"
              iconBtn
              iconCss={RAFButtonConstant.Complete.IconCss}
            />
          </div>
        </>
      );
    }
    else if (
      state.checklistItemTransRow.RequiresApproval &&
      state.checklistItemTransRow.Status ===
      RAFChecklistItemTransStatus.Approved
    ) {
      return (
        <>
          <div className="col-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Reject}
              onClick={() =>
                onClickApproveRejectBtn(RAFChecklistItemTransStatus.Rejected)
              }
              className="btn_state_danger semi_dark new_style raf_sm"
              iconCss={RAFButtonConstant.Clear.IconCss}
            />
          </div>
        </>
      );
    }
    else if (
      state.checklistItemTransRow.RequiresApproval &&
      state.checklistItemTransRow.Status ===
      RAFChecklistItemTransStatus.Rejected
    ) {
      return (
        <>
          <div className="col-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Approve}
              onClick={() =>
                onClickApproveRejectBtn(RAFChecklistItemTransStatus.Approved)
              }
              className="btn_brand_primary semi_dark new_style raf_sm"
              iconCss={RAFButtonConstant.Complete.IconCss}
            />
          </div>
        </>
      );
    }
    else {
      return null;
    }
  };

  const getUploadReUploadContent = () => {
    if (state.checklistItemTransRow.AllowUserUpload || IsSuperAdmin()) {
      const btnAction =
        (state.checklistItemTransRow.Status === RAFChecklistItemTransStatus.NotStarted || state.checklistItemTransRow.Status === RAFChecklistItemTransStatus.NotUploaded)
          ? RAFButtonConstant.Upload
          : RAFButtonConstant.Reupload;
      return (
        <div className="col-auto">
          <RAFButtonComponent
            isPrimary
            action={btnAction}
            onClick={() => onClickUploadItem()}
            className="btn_brand_primary transparent"
            iconBtn
          />
          {state.showUploadContent === true && (
            <DialogComponent
              header={
                isNotNullAndUndefined(state.checklistItemTransRow) ?
                  state.checklistItemTransRow.Title : "Upload File"
              }
              showCloseIcon
              visible={state.showUploadContent}
              cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
              content={uploadAndLinkDocumentContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={uploadDialogClose.bind(this)}
              id="manageUploadDocumentDialog"
              zIndex={1300}
              open={PreventFocusOnDialogOpen}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const getActivityItemContent = () => {
    return (
      <CreateActivitySummary
        key={state.activitySummaryKey}
        relatedTo={state.checklistItemTransRow.Title}
        relatedToUID={state.checklistItemTransRow.UID}
        moduleName={moduleName}
        paddingClassName="p-0"
        contentPaddingClassName="p-0"
      />
    );
  };

  const getDescriptionContent = () => {
    const { checklistItemTransRow } = state;
    if (IsNotNullOrWhiteSpace(checklistItemTransRow.Description)) {
      return (
        <div className="row gy-1">
          <div className="col-12">
            <div className="details-label content_neutral_base">
              Description
            </div>
          </div>
          <div className="col-12">
            <CustomCardWidget cardContentClassName="p-2" cardClassName="surface_neutral_base">
              <div className="body_2">
                <span
                  className="description-text body_2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(checklistItemTransRow.Description),
                  }}
                ></span>
              </div>
            </CustomCardWidget>
          </div>
        </div>
      );
    }
  };

  const getContent = () => {
    return (
      <>
        <div className="col-12">
          <div className="row gy-2">
            <div className="col-12">
              <div className="subtitle_1 semi_bold">
                <span>Notes</span>
              </div>
            </div>
            <div className="col-12">
              {getActivityItemContent()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const downloadDocument = () => {
    downloadDocumentFile(state.contentLibraryRow).then((status) => { });
  };

  //delete checklist item

  const onClickDeleteChecklistItemTrans = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteChecklistItemTrans.bind(this) },
      showCloseIcon: false,
      title: " Delete Compliance Items",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  };

  const DeleteChecklistItemTrans = () => {
    const { checklistItemTransRow } = state;
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    DeleteRecord(checklistItemTransRow.UID, moduleName)
      .then((response) => {
        deleteDialog.hide();
        hideProgress(progressDiv);
        if (response) {
          showSuccessToast(RAFActionMessage.RecordDeleted);
          if (props.onDeleteChecklistItemTrans) {
            props.onDeleteChecklistItemTrans();
          }
        } else {
          showWarningToast(RAFActionMessage.RecordNotDeleted);
        }
      })
      .catch((error) => error);
  };

  //ExpiryDate start 
  const getExpiryDateDisplayContent = () => {
    const { checklistItemTransRow } = state;

    const expiryDate = convertUTCDateToLocalTimezone(checklistItemTransRow.ExpiryDate);
    const expiryDateDisplayValue = isNotNullAndUndefined(expiryDate) ? moment(expiryDate).format(MomentFormats.DATE) : "Not Set";

    return (
      <>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-12">
              <span className="details-label">
                Expiry date
              </span>
            </div>
            {/* <RAFFieldLabel field={propertyOf<ChecklistItemTransRow>("ExpiryDate")} label={"Expiry date"} labelClassName="col-3" /> */}
            <div className={`col-12`} id='expiryDateDisplayValueDiv'>
              <div className="row g-2 align-items-center">
                <div className="col-auto">
                  <div className="d-flex header-text-sm-light">
                    <div className="">
                      {expiryDateDisplayValue}
                    </div>
                  </div>
                </div>
                <RAFPermissionRender permissionName={props.permissionNameEditDocumentExpiryDate}>
                  <Fragment>
                    <div className="col-auto">
                      <i className="fat fa-pipe"></i>
                    </div>
                    <div className="col-auto">
                      <ButtonComponent type="button"
                        className="link-button" content={RAFButtonConstant.Edit.DisplayName}
                        iconCss={RAFButtonConstant.Edit.IconCss}
                        onClick={() => onEditExpiryDateButtonClick()}
                      />
                    </div>
                  </Fragment>
                </RAFPermissionRender>
              </div>
            </div>
            <RAFPermissionRender permissionName={props.permissionNameEditDocumentExpiryDate}>
              <div className={`col-12 d-none`} id='expiryDateInputValueDiv' >
                <RAFForm
                  formRef={(g) => {
                    return (rafFormRef = g);
                  }}
                  layout={RAFLayout.TwoColumnLayout}
                  submitOnEnterKey={false}
                  initialValues={state.checklistItemTransRow}
                  onSubmit={(value) => { }}
                >
                  <>
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        <ACDatePicker
                          field={propertyOf<ChecklistItemTransRow>('ExpiryDate')}
                          label="Expiry Date"
                          showLabel={false}
                          required
                          formGroupClassName="mb-0 raf_sm"
                        />
                      </div>
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Cancel}
                          iconBtn
                          onClick={() => onClearExpiryDateButtonClick()}
                          className="e-outline new_style raf_sm"
                          enableToolTip={false}
                        ></RAFButtonComponent>
                      </div>
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Ok}
                          iconBtn
                          onClick={() => onCickExpiryDateOkButton()}
                          className="outline btn_brand_primary new_style raf_sm"
                          enableToolTip={false}
                        ></RAFButtonComponent>
                      </div>
                    </div>
                  </>
                </RAFForm>
              </div>
            </RAFPermissionRender>
          </div>
        </div>
      </>
    );
    //   if (checklistItemTransRow.HasExpiryDate) {
    //   } else {
    //   return null;
    // }
    //   if (isNotNullAndUndefined(state.contentLibraryRow)) {
    //   } else {
    //   return (
    //     <div className="col-md-6">
    //       <RAFDetailFieldCustom
    //         title="Expiry date"
    //         value={checklistItemTransRow.ExpiryDate}
    //         fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
    //         rowClassName="gy-1"
    //       />
    //     </div>
    //   );
    // }
  };

  const onCickExpiryDateOkButton = () => {
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        onClearExpiryDateButtonClick();
        onClickSaveExpiryDateField();
      }
    }
  };

  const onClickSaveExpiryDateField = async () => {
    const { checklistItemTransRow } = state;

    const formValue: ChecklistItemTransRow = getFormValue(rafFormRef);
    const expiryDateFormValue = isNotNullAndUndefined(formValue) && isNotNullAndUndefined(formValue.ExpiryDate) ? new Date(formValue.ExpiryDate) : null;
    const expiryDate = isNotNullAndUndefined(checklistItemTransRow) && isNotNullAndUndefined(checklistItemTransRow.ExpiryDate) ? new Date(checklistItemTransRow.ExpiryDate) : null;
    let allowSubmit = false;
    if (isNotNullAndUndefined(expiryDateFormValue) && isNotNullAndUndefined(expiryDate)) {
      if (expiryDateFormValue.getFullYear() !== expiryDate.getFullYear() ||
        expiryDateFormValue.getMonth() !== expiryDate.getMonth() ||
        expiryDateFormValue.getDate() !== expiryDate.getDate()) {
        allowSubmit = true;
      } else {
        allowSubmit = false;
      }
    } else if (isNullOrUndefined(expiryDateFormValue) && isNullOrUndefined(expiryDate)) {
      allowSubmit = false;
    } else if (isNotNullAndUndefined(expiryDateFormValue) || isNotNullAndUndefined(expiryDate)) {
      allowSubmit = true;
    }

    if (allowSubmit) {
      const submitChecklistItemTransFormValue = new ChecklistItemTransRow();
      submitChecklistItemTransFormValue.UID = checklistItemTransRow.UID;
      submitChecklistItemTransFormValue.ExpiryDate = expiryDateFormValue;
      let progressDiv = showProgress(
        "#checklistItemTransDetailsContent_outerDiv"
      );
      const response = await saveChecklistItemTransAPI(submitChecklistItemTransFormValue);
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.entityId)) {
        const newchecklistItemTransRow = state.checklistItemTransRow;
        newchecklistItemTransRow.ExpiryDate = expiryDateFormValue;
        setState({ checklistItemTransRow: newchecklistItemTransRow });
        if (props.onSaveChecklistItemTrans) {
          props.onSaveChecklistItemTrans();
        }
      }
    }
  };

  const onClearExpiryDateButtonClick = () => {
    let expiryDateDisplayValueDiv = document.getElementById(`expiryDateDisplayValueDiv`);
    let expiryDateInputValueDiv = document.getElementById(`expiryDateInputValueDiv`);
    if (isNotNullAndUndefined(expiryDateDisplayValueDiv) && isNotNullAndUndefined(expiryDateInputValueDiv)) {
      expiryDateInputValueDiv.classList.add('d-none');
      expiryDateDisplayValueDiv.classList.remove('d-none');
    }
  };

  const onEditExpiryDateButtonClick = () => {
    let expiryDateDisplayValueDiv = document.getElementById(`expiryDateDisplayValueDiv`);
    let expiryDateInputValueDiv = document.getElementById(`expiryDateInputValueDiv`);
    if (isNotNullAndUndefined(expiryDateDisplayValueDiv) && isNotNullAndUndefined(expiryDateInputValueDiv)) {
      expiryDateDisplayValueDiv.classList.add('d-none');
      expiryDateInputValueDiv.classList.remove('d-none');
      if (isNotNullAndUndefined(rafFormRef)) {
        setFormValue(rafFormRef, propertyOf<ChecklistItemTransRow>('ExpiryDate'), state.checklistItemTransRow.ExpiryDate);
      }
    }
  };
  //ExpiryDate end

  //edit check list trans start
  const onClickEditChecklistItemTrans = () => {
    setState({ showChecklistItemTransEditDlgContent: true });
  };

  const checklistItemTransEditDlgContent = () => {
    if (state.showChecklistItemTransEditDlgContent) {
      return (
        <ManageChecklistTemplateTrans
          objectUID={state.checklistItemTransRow.UID}
          onClose={closeChecklistItemTransEditDlgContent}
          onSave={onUpdateChecklistItemTrans}
          onDelete={onDeleteChecklistItemTrans}
          isActive
        />
      );
    } else {
      return <></>;
    }
  };

  const onUpdateChecklistItemTrans = () => {
    refreshOnUpdate();
    if (props.onSaveChecklistItemTrans) {
      props.onSaveChecklistItemTrans();
    }
  };

  const onDeleteChecklistItemTrans = () => {
    setState({ showChecklistItemTransEditDlgContent: false });
    if (props.onDeleteChecklistItemTrans) {
      props.onDeleteChecklistItemTrans();
    }
  };

  const closeChecklistItemTransEditDlgContent = () => {
    setState({ showChecklistItemTransEditDlgContent: false });
  };
  //edit check list trans end

  if (state.isLoading === false) {
    if (state.noContent === false) {
      const { checklistItemTransRow } = state;
      let userUploadDisplayText = "Admin Upload";
      let userUploadDisplayStatus = "raf_badge_success";
      if (checklistItemTransRow.AllowUserUpload) {
        userUploadDisplayText = "User Upload";
        userUploadDisplayStatus = "raf_badge_quaternary";
      }
      const status = checklistItemTransRow.DocumentStatus;
      return (
        <div
          className="e-dlg-content-outer"
          id="checklistItemTransDetailsContent_outerDiv"
        >
          <div className="e-dlg-body-content pt-3">
            <div className="row gx-0 gy-3">
              <div className="col-12">
                <CustomCardWidget cardClassName="pointer overflow-hidden"
                  footerTemplate={
                    <div>
                    </div>
                  }
                >
                  <div className="row gx-0 gy-3">
                    <div className="col-12">
                      <div className="row g-2 align-items-center">
                        <div className="col-12">
                          <div className="row gy-1 gx-0">
                            <div className="col-12">
                              <div className="subtitle_2 semi_bold">
                                {checklistItemTransRow.Title}
                              </div>
                            </div>
                            <div className="col-12">
                              <RAFDetailsValueWithSeparator
                                outerClassName="h-auto"
                              >
                                <RAFDetailFieldCustom
                                  value={status}
                                  displayValue={status}
                                  title="DocumentStatus"
                                  moduleName={CareEsioEntity.ChecklistItemTrans.EntityName}
                                  isColorOption
                                  mode="ringView"
                                  field="DocumentStatus"
                                  showLabel={false}
                                />
                                <div className="row gx-2 gy-0">
                                  {checklistItemTransRow.IsRequired &&
                                    <div className="col-auto">
                                      <div className="raf_badge raf_xsm raf_badge_danger">
                                        Required
                                      </div>
                                    </div>
                                  }
                                  {checklistItemTransRow.IsCompliance &&
                                    <div className="col-auto">
                                      <div className="raf_badge raf_xsm raf_badge_primary">
                                        Compliance
                                      </div>
                                    </div>
                                  }
                                  <div className="col-auto">
                                    <div className={`raf_badge raf_xsm ${userUploadDisplayStatus}`}>
                                      {userUploadDisplayText}
                                    </div>
                                  </div>
                                </div>
                              </RAFDetailsValueWithSeparator>
                            </div>
                          </div>
                        </div>
                        {isNotNullAndUndefined(checklistItemTransRow.IssueDate) && (
                          <div className="col-md-6">
                            <RAFDetailFieldCustom
                              title="Issue Date"
                              value={checklistItemTransRow.IssueDate}
                              fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                              rowClassName="gy-1"
                            />
                          </div>
                        )}
                        {getExpiryDateDisplayContent()}
                        {isNotNullAndUndefined(checklistItemTransRow.ReviewDate) && (
                          <div className="col-md-6">
                            <RAFDetailFieldCustom
                              title="Review Date"
                              value={checklistItemTransRow.ReviewDate}
                              fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                              rowClassName="gy-1"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {IsNotNullOrWhiteSpace(checklistItemTransRow.Description) && (
                      <>
                        <div className="col-12">
                          <div className="custom__card__separator custom__card__separator__light"></div>
                        </div>
                        <div className="col-12">
                          {getDescriptionContent()}
                        </div>
                      </>
                    )}
                  </div>
                </CustomCardWidget>
              </div>
              {isNotNullAndUndefined(state.contentLibraryRow) && (
                <div className="col-12">
                  <DocumentLeftCardContent contentLibraryRow={state.contentLibraryRow} hideRevisionAndStatus />
                </div>
              )}
              {getContent()}
            </div>
          </div>
          <div className="e-dlg-footerContent ">
            <div className="w-100">
              <div className="row gx-2 justify-content-between">
                <div className="col-auto">
                  <div className="row gx-2">
                    <RAFPermissionRender
                      permissionName={props.deleteDocumentPermissionName}
                    >
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Delete}
                          iconBtn
                          onClick={() => onClickDeleteChecklistItemTrans()}
                          className="btn_state_danger transparent"
                        />
                      </div>
                    </RAFPermissionRender>
                    {getUploadReUploadContent()}
                    {isNotNullAndUndefined(state.contentLibraryRow) && (
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Download}
                          iconBtn
                          onClick={() => downloadDocument()}
                          className="btn_state_success transparent"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="row gx-2">
                    <RAFPermissionRender permissionName={props.permissionNameEditDocumentProperties}>
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Edit}
                          onClick={() => onClickEditChecklistItemTrans()}
                          className="btn_brand_primary outline"
                          enableToolTip={false}
                        />
                      </div>
                    </RAFPermissionRender>
                    {getApproveRejectContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {state.showChecklistItemTransEditDlgContent && (
            <DialogComponent
              header={'Update Compliance Document'}
              showCloseIcon
              visible={state.showChecklistItemTransEditDlgContent}
              cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
              id={`dlg_checklistTemplateItem_${moduleName}`}
              content={checklistItemTransEditDlgContent.bind(
                this
              )}
              isModal
              target="body"
              closeOnEscape={false}
              close={closeChecklistItemTransEditDlgContent.bind(
                this
              )}
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(ChecklistItemTransDetailsContent);
