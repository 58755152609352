import { propertyOf } from "../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../RAFComponents/models/Common/RAFEntityBase";
import { ILookupRow } from "../../../RAFComponents/models/CompositeTypes/ILookupRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";

export class IChecklistTemplateRow {
    UID?: string;
    Title?: string;
    Description?: string;
    Status?: string;
    EntityUID?: string;
    Entity?: string;
    EntityType?: string;
    ChecklistTemplateId?: string;
    PortalUID?: string;
    Portal?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class ChecklistTemplateRow extends IChecklistTemplateRow implements RAFEntityBase, ILookupRow {
    SelectEntityUID?: string;
    getClassName?() {
        return CareEsioEntity.ChecklistTemplate.EntityName;
    }
    getIdField?(): string {
        return propertyOf<ChecklistTemplateRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "ChecklistTemplate/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "ChecklistTemplate/Save";
    }
    getGroupByUrl?(): string {
        return "ChecklistTemplate/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "ChecklistTemplate/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
