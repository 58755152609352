import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { RAFActionMessage, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { IDialogProps, IsNullOrWhiteSpace, isEmptyArray, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import ChecklistTemplateItemCardContent from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemCardContent";
import { ChecklistTemplateItemRow } from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemRow";
import { ChecklistTemplateRow } from "../ChecklistTemplate/ChecklistTemplateRow";
import { ChecklistItemTransSaveRequest, getChecklistTemplateItemGetRequiredItems, saveListchecklistTemplateItems } from "./ChecklistItemTransHelper";
import './checklist_item_trans_style.scss';

interface IProps {
    relatedTo: string;
    relatedToUID: string;
    relatedToType: string;
}

interface IState {
    isLoading: boolean;
    checkListTemplateItems: ChecklistTemplateItemRow[];
    selectedChecklistTemplateItemsUID: string[];
}

function CreateChecklistItemTransDocument({ ...props }: PropsWithChildren<IProps & IDialogProps>) {
    const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;
    const outerDivId = `manageupdate_checklist_item_trans`;

    let rafForm: FormRenderProps | null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            checkListTemplateItems: [],
            selectedChecklistTemplateItemsUID: null
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        if (props.isActive) {
            setState({ isLoading: true, selectedChecklistTemplateItemsUID: null });
            setState({ isLoading: false });
        }
    };

    const onSubmitChecklistItemTrans = async (value: any) => {
        const { checkListTemplateItems, selectedChecklistTemplateItemsUID } = state;

        if (isEmptyArray(state.selectedChecklistTemplateItemsUID)) {
            showWarningToast("Please select checklist items");
            return;
        }

        let progressDiv = showProgress(`#${outerDivId}`);

        const selectedChecklistItems: LookUpRow[] = (isNotEmptyArray(selectedChecklistTemplateItemsUID) && isNotEmptyArray(checkListTemplateItems)) ? selectedChecklistTemplateItemsUID.reduce((acc, uid) => {
            const item = checkListTemplateItems.find(item => item.UID === uid);
            if (item) {
                acc.push({ UID: item.UID, Value: item.Title });
            }
            return acc;
        }, []) : [];

        const checklistItemTransSaveRequest = new ChecklistItemTransSaveRequest();
        checklistItemTransSaveRequest.RelatedTo = props.relatedTo;
        checklistItemTransSaveRequest.RelatedToUID = props.relatedToUID;
        checklistItemTransSaveRequest.RelatedToType = props.relatedToType;
        checklistItemTransSaveRequest.ChecklistItems = selectedChecklistItems;

        const response = await saveListchecklistTemplateItems(checklistItemTransSaveRequest);
        hideProgress(progressDiv);
        if (response) {
            showSuccessToast(RAFActionMessage.RecordSaved);
            if (props.onSave) {
                props.onSave();
            }
        } else {
            showWarningToast(RAFActionMessage.RecordNotSaved);
        }
    };

    const onChangeCheckBoxValue = (
        isChecked: boolean,
        selectedItemUID: string
    ) => {
        const newSelectedChecklistTemplateItemsUID = isNotEmptyArray(state.selectedChecklistTemplateItemsUID)
            ? [...state.selectedChecklistTemplateItemsUID]
            : [];
        if (isChecked) {
            if (!newSelectedChecklistTemplateItemsUID.includes(selectedItemUID)) {
                newSelectedChecklistTemplateItemsUID.push(selectedItemUID);
            }
        } else {
            if (newSelectedChecklistTemplateItemsUID.includes(selectedItemUID)) {
                newSelectedChecklistTemplateItemsUID.splice(
                    newSelectedChecklistTemplateItemsUID.indexOf(selectedItemUID),
                    1
                );
            }
        }
        setState({ selectedChecklistTemplateItemsUID: newSelectedChecklistTemplateItemsUID });
    };

    const renderChecklistTemplateItemsContent = () => {
        const { selectedChecklistTemplateItemsUID } = state;
        if (isNotEmptyArray(state.checkListTemplateItems)) {
            const parentCheckListTemplateItems = state.checkListTemplateItems.filter(x => IsNullOrWhiteSpace(x.ParentUID));
            return (
                <div
                    className="col-12"
                    id={`care_${CareEsioEntity.ChecklistTemplateItem.EntityName}_div`}
                >
                    <div className="row gy-3 gx-0">

                        <div className="col-12">
                            <div className="checklist_template_item_card_header">
                                <span>
                                    {CareEsioEntity.ChecklistItemTrans.DisplayName}
                                </span>
                            </div>
                        </div>
                        {parentCheckListTemplateItems.map((item) => {
                            const childCheckListTemplateItems = state.checkListTemplateItems.filter(x => x.ParentUID === item.UID);
                            const isChecked = isNotEmptyArray(selectedChecklistTemplateItemsUID)
                                ? selectedChecklistTemplateItemsUID.includes(item.UID)
                                : false;
                            return (
                                <div className="col-12" key={item.UID}>
                                    <CustomCardWidget cardClassName="overflow-hidden"
                                        removeContentPadding>
                                        <ChecklistTemplateItemCardContent
                                            key={item.UID}
                                            checklistTemplateItemRow={item}
                                            checklistTemplateEntity={null}
                                            checklistTemplateEntityDisplayName={null}
                                            allCheckListTemplateItems={state.checkListTemplateItems}
                                            childCheckListTemplateItems={childCheckListTemplateItems}
                                            allowAddEdit={false}
                                            isCollapsed={false}
                                            onSave={null}
                                            showCheckBox
                                            isChecked={isChecked}
                                            selectedChecklistTemplateItemsUID={selectedChecklistTemplateItemsUID}
                                            onChangeCheckBoxValue={onChangeCheckBoxValue}
                                        />
                                    </CustomCardWidget>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    <RAFEmptyState
                        title={`Checklist Template Item`}
                        body={`No Checklist Template Items available at this time.`}
                        displayStyle="TextOnly"
                    />
                </div>
            );
        }
    };

    const renderChecklistTemplateItemsContent1 = () => {
        const { checkListTemplateItems, selectedChecklistTemplateItemsUID } = state;
        const checklistTemplateItemListLength = isNotEmptyArray(checkListTemplateItems) ? checkListTemplateItems.length : 0;
        return (
            <div className="col-12">
                <CustomCardWidget title={`Check List Items (${checklistTemplateItemListLength})`} cardClassName="surface_neutral_base" cardHeaderClassName="subtitle_1 semi_bold">
                    {isNotEmptyArray(checkListTemplateItems) ? (
                        <>
                            {checkListTemplateItems.map((checklistTemplateItemRow: ChecklistTemplateItemRow) => {
                                const isChecked = isNotEmptyArray(selectedChecklistTemplateItemsUID)
                                    ? selectedChecklistTemplateItemsUID.includes(checklistTemplateItemRow.UID)
                                    : false;
                                return (
                                    <div
                                        className="row gx-2 gx-md-3 pointer"
                                        key={checklistTemplateItemRow.UID}
                                        onClick={() => onChangeCheckBoxValue(!isChecked, checklistTemplateItemRow.UID)}
                                    >
                                        <div
                                            key={isChecked ? isChecked.toString() : ""}
                                            className="col-auto mt-2"
                                        >
                                            <CheckBoxComponent
                                                name={checklistTemplateItemRow.UID}
                                                value={checklistTemplateItemRow.UID}
                                                cssClass="e-checkbox-light"
                                                // change={(e) => onChangeCheckBoxValue(e.checked, checklistTemplateItemRow.UID)}
                                                checked={isChecked ?? false}
                                            />
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="row gx-0 gy-1">
                                                <div className="col-12">
                                                    <span className="secondary-header-bold-text">{checklistTemplateItemRow.Title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )
                        :
                        (
                            <div className="container-fluid px-0">
                                <RAFDeletedRecordState
                                    title='No Check list items to display.'
                                />
                            </div>
                        )
                    }
                </CustomCardWidget>
            </div>
        );
    };

    const onChangeChecklistTemplate = async (label: string, value: string) => {
        let progressDiv = showProgress(`#${outerDivId}`);
        const checkListTemplateItems: ChecklistTemplateItemRow[] = await getChecklistTemplateItemGetRequiredItems(value, props.relatedToUID, props.relatedToType);
        hideProgress(progressDiv);
        setState({ checkListTemplateItems: checkListTemplateItems, selectedChecklistTemplateItemsUID: null });
    };

    const getChecklistTemplateLookUp = () => {
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];

        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push('Active');
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<ChecklistTemplateRow>("Status");
        customFilter.Rules.push(filter);

        if (isNotNullAndUndefined(props.relatedToType)) {
            let filter2: RAFCustomFilter = {};
            let filterVal2: string[] = [];
            filterVal2.push(props.relatedToType);
            filter2.Operator = RAFCustomOperator.Equal;
            filter2.Value = filterVal2;
            filter2.Field = propertyOf<ChecklistTemplateRow>("EntityType");
            customFilter.Rules.push(filter2);
        }

        return (
            <RAFLookUpMUI
                field="ChecklistTemplate"
                label="Requirements Template"
                placeholder="Select Requirements Template"
                url={'ChecklistTemplate/Lookup'}
                moduleName={moduleName}
                showClearButton={false}
                customFilter={customFilter}
                onChanged={onChangeChecklistTemplate}
                required
                formGroupClassName="mb-0"
            />
        );
    };

    const getInputFormContent = () => {
        return (
            <div className="row gx-2 gy-3">
                {getChecklistTemplateLookUp()}
                {renderChecklistTemplateItemsContent()}
            </div>
        );
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            return (
                <RAFEntityProvider moduleName={moduleName}>
                    <RAFAttributeRelatedListProvider moduleName={moduleName}>
                        <RAFForm
                            //initialValues={state.employeeRow}
                            formRef={(g) => (rafForm = g)}
                            layout={RAFLayout.TwoColumnLayout}
                            onSubmit={onSubmitChecklistItemTrans}
                            className="h-100"
                        >
                            <div className="e-dlg-content-outer" id={outerDivId}>
                                <div className="e-dlg-body-content">
                                    {getInputFormContent()}
                                </div>
                                <div className="e-dlg-footerContent ">
                                    <div className="w-100">
                                        <div className="row gx-2 justify-content-end">
                                            <div className="col-auto">
                                                <RAFButtonComponent
                                                    type="button"
                                                    action={RAFButtonConstant.Cancel}
                                                    onClick={props.onClose}
                                                    idString="CreateContent"
                                                    className="form-custom-button"
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <RAFButtonComponent
                                                    type="button"
                                                    isPrimary
                                                    showIcon={false}
                                                    action={RAFButtonConstant.Add}
                                                    onClick={() => rafForm && rafForm.form.submit()}
                                                    idString="CreateContent"
                                                    disabled={rafForm && rafForm.submitting}
                                                    className="form-custom-button"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RAFForm>
                    </RAFAttributeRelatedListProvider>
                </RAFEntityProvider>
            );
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Loading..." />
                </div>
            );
        }
    }
    return (
        <></>
    );
}

export default React.memo(CreateChecklistItemTransDocument);