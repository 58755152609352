import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

export class EmployeeAvailabilityTimeRangeRow {
    StartTime?: string;
    EndTime?: string;
    IsFavourite?: boolean;
}

export class EmployeeAvailabilityRow {
    IsAvailable?: boolean;
    DayOfWeek?: string;
    TimeRange?: EmployeeAvailabilityTimeRangeRow[];
}

export class EmployeeAvailabilityFormRow { //internal purpose
    UID: string;
    EmployeeAvailability: EmployeeAvailabilityRow[];
}

export class EmployeeWorkingDay {
    start_time?: string;
    end_time?: string;
    day_of_week?: string;
    is_favourite?: boolean;
}

export class IEmployeeRow {
    UID?: string;
    FirstName?: string;
    LastName?: string;
    Name?: string;
    Gender?: string;
    DateOfBirth?: Date;
    EmployeeId?: string;

    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCity?: string;
    AddressState?: string;
    AddressCountry?: string;

    EmailInfoPersonal?: string;
    EmailInfoWork?: string;

    PhonePersonal?: string;
    PhoneTollFree?: string;
    PhoneWork?: string;
    PhoneHome?: string;

    WorkingDay?: EmployeeWorkingDay[];

    Portal?: string;
    PortalUID?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;

    IsActive?: boolean;
}

export class EmployeeRow extends IEmployeeRow implements RAFEntityBase, ILookupRow {
    getClassName?() {
        return 'employee';
    }
    getIdField?(): string {
        return propertyOf<EmployeeRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "Employee/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "Employee/Save";
    }
    getGroupByUrl?(): string {
        return "Employee/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "Employee/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
