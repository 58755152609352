import { DialogUtility } from "@syncfusion/ej2-popups";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, { Condition } from "../../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFMultiSelectDropdown from "../../../../RAFComponents/Inputs/RAFMultiSelectDropdown";
import RAFNumber from "../../../../RAFComponents/Inputs/RAFNumber";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import { getFormValue, setFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { getLookUpItems } from "../../../../RAFComponents/helpers/AutoCompleteMUIHelper";
import {
  IDialogProps,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import ACDropdown from "../../../../components/shared/ACFormFields/ACDropdown";
import ACTextBox from "../../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { saveChecklistTemplateItemAPI } from "./ChecklistTemplateItemHelper";
import {
  ChecklistTemplateItemRow,
  ChecklistTemplateItemStatus,
  ChecklistTemplateItemType,
} from "./ChecklistTemplateItemRow";

const enum AppliesToTypeValue {
  AllEmployees = "All Employees",
  ALLClients = "All Clients",
}

interface IProps {
  initialValues?: ChecklistTemplateItemRow;
  checklistTemplateUID: string;
  checklistTemplate: string;
  checklistTemplateEntity: string;
  checklistTemplateEntityDisplayName: string;
  checklistTemplateParentUID?: string;
  checklistTemplateParent?: string;
  isChildItemsExist?: boolean;
  onDelete?: () => void;
  objectUID?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  employeeLookup: LookUpRow[];
  teamsLookup: LookUpRow[];
  careRecipientLookup: LookUpRow[];
  checklistTemplateItemRow: ChecklistTemplateItemRow;
}

function ManageChecklistTemplateItem({
  checklistTemplateEntity,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = CareEsioEntity.ChecklistTemplateItem.EntityName;

  const outerDivId = `manageupdate_checklistTemplateItem_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      employeeLookup: null,
      teamsLookup: null,
      careRecipientLookup: null,
      checklistTemplateItemRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [
        checklistTemplateItemRow,
        employeeLookup,
        teamsLookup,
        careRecipientLookup,
      ] = await Promise.all([
        getIntitailChecklistTemplateItemRow(),
        getLookUpItems(
          "User/EmployeeLookup",
          0,
          0,
          null,
          RAFEntityName.User,
          null
        ),
        getLookUpItems("Team/Lookup", 0, 0, null, RAFEntityName.Team, null),
        getLookUpItems(
          "CareRecipient/Lookup",
          0,
          0,
          null,
          CareEsioEntity.CareRecipient.EntityName,
          null
        ),
      ]);

      if (isNotNullAndUndefined(checklistTemplateItemRow)) {
        setState({
          isLoading: false,
          noContent: false,
          checklistTemplateItemRow,
          employeeLookup,
          teamsLookup,
          careRecipientLookup,
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailChecklistTemplateItemRow = () => {
    return new Promise<ChecklistTemplateItemRow>(async (resolve) => {
      if (isNotNullAndUndefined(checklistTemplateEntity)) {
        if (isNotNullAndUndefined(props.objectUID)) {
          const checklistTemplateItemRow: ChecklistTemplateItemRow =
            await RetrieveRecord(props.objectUID, moduleName);
          if (
            isNotNullAndUndefined(checklistTemplateItemRow) &&
            isNotNullAndUndefined(checklistTemplateItemRow.UID)
          ) {
            const appliesTo = checklistTemplateItemRow.AppliesTo;
            if (isNotEmptyArray(appliesTo)) {
              if (
                checklistTemplateEntity === RAFEntityName.Employee
              ) {
                if (
                  appliesTo.length === 1 &&
                  appliesTo[0].UID === null &&
                  appliesTo[0].Value === AppliesToTypeValue.AllEmployees
                ) {
                  checklistTemplateItemRow.AppliesToType = "All";
                } else {
                  const selectedEmployees = appliesTo.filter(
                    (x) => x.Type === CareEsioEntity.CareRecipient.EntityName
                  );
                  const selectedTeams = appliesTo.filter(
                    (x) => x.Type === RAFEntityName.Team
                  );
                  checklistTemplateItemRow.SelectedEmployees =
                    selectedEmployees.map((x) => x.UID);
                  checklistTemplateItemRow.SelectedTeams = selectedTeams.map(
                    (x) => x.UID
                  );
                  checklistTemplateItemRow.AppliesToType = "Select";
                }
              } else {
                if (
                  appliesTo.length === 1 &&
                  appliesTo[0].UID === null &&
                  appliesTo[0].Value === AppliesToTypeValue.ALLClients
                ) {
                  checklistTemplateItemRow.AppliesToType = "All";
                } else {
                  checklistTemplateItemRow.AppliesToType = "Select";
                  checklistTemplateItemRow.SelectedClients = appliesTo.map(
                    (x) => x.UID
                  );
                }
              }
            } else {
              checklistTemplateItemRow.AppliesToType = "All";
            }
            resolve(checklistTemplateItemRow);
          } else {
            resolve(null);
          }
        } else {
          if (props.initialValues) {
            resolve(props.initialValues);
          } else {
            let initialObject = new ChecklistTemplateItemRow();
            initialObject.Status = ChecklistTemplateItemStatus.Active;
            if (isNotNullAndUndefined(props.checklistTemplateParentUID)) {
              initialObject.ParentUID = props.checklistTemplateParentUID;
              initialObject.Parent = props.checklistTemplateParent;
              initialObject.Type = ChecklistTemplateItemType.Item;
              initialObject.AllowUserUpload = true;
              initialObject.HasExpiryDate = true;
            } else {
              initialObject.Type = ChecklistTemplateItemType.Section;
              initialObject.AppliesToType = "All";
            }
            initialObject.ChecklistTemplateUID = props.checklistTemplateUID;
            initialObject.ChecklistTemplate = props.checklistTemplate;

            resolve(initialObject);
          }
        }
      } else {
        resolve(null);
      }
    });
  };

  const onClickPushBtn = () => {
    if (isNotNullAndUndefined(rafForm)) {
      if (rafForm.invalid) {
        rafForm.form.submit();
      } else {
        showPushAlertMessage();
      }
    }
  };

  const showPushAlertMessage = () => {
    const entity = props.checklistTemplateEntityDisplayName ?? '';
    const formValue = getFormValue(rafForm);
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: `This change might affect the existing ${entity} documents. Are you sure you want to push?`,
      okButton: { text: "Yes", click: onSubmitChecklistTemplateItem.bind(this, formValue, 'ChecklistTemplateItem/SaveAndSync') },
      title: `Confirm!`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  const onSubmitChecklistTemplateItem = async (
    formValue: ChecklistTemplateItemRow, url?: string
  ) => {
    if (isNotNullAndUndefined(deleteDialog)) {
      deleteDialog.hide();
    }
    const { employeeLookup, teamsLookup, careRecipientLookup } = state;
    let progressDiv = showProgress(`#${outerDivId}`);

    const value: ChecklistTemplateItemRow = R.clone(formValue);

    const appliesToType = value.AppliesToType;
    const selectedEmployees = value.SelectedEmployees;
    const selectedTeams = value.SelectedTeams;
    const selectedClients = value.SelectedClients;

    delete value.AppliesToType;
    delete value.SelectedEmployees;
    delete value.SelectedTeams;
    delete value.SelectedClients;

    if (
      checklistTemplateEntity === RAFEntityName.Employee &&
      appliesToType === "Select"
    ) {
      if (
        !isNotEmptyArray(selectedEmployees) &&
        !isNotEmptyArray(selectedTeams)
      ) {
        showWarningToast("Please select atleast one employee or team.");
        hideProgress(progressDiv);
        return;
      }
    }

    if (value.Type === ChecklistTemplateItemType.Section) {
      let appliesToValue: RAFRelatedToRow[] = [];
      if (appliesToType === "Select") {
        if (checklistTemplateEntity === RAFEntityName.Employee) {
          if (
            isNotEmptyArray(selectedEmployees) &&
            isNotEmptyArray(employeeLookup)
          ) {
            selectedEmployees.forEach((item) => {
              const selectedItem = employeeLookup.find((x) => x.UID === item);
              if (isNotNullAndUndefined(selectedItem)) {
                appliesToValue.push({
                  UID: selectedItem.UID,
                  Value: selectedItem.Value,
                  Type: CareEsioEntity.CareRecipient.EntityName,
                });
              }
            });
          }
          if (isNotEmptyArray(selectedTeams) && isNotEmptyArray(teamsLookup)) {
            selectedTeams.forEach((item) => {
              const selectedItem = teamsLookup.find((x) => x.UID === item);
              if (isNotNullAndUndefined(selectedItem)) {
                appliesToValue.push({
                  UID: selectedItem.UID,
                  Value: selectedItem.Value,
                  Type: RAFEntityName.Team,
                });
              }
            });
          }
        } else {
          if (
            isNotEmptyArray(selectedClients) &&
            isNotEmptyArray(careRecipientLookup)
          ) {
            selectedClients.forEach((item) => {
              const selectedItem = careRecipientLookup.find(
                (x) => x.UID === item
              );
              if (isNotNullAndUndefined(selectedItem)) {
                appliesToValue.push({
                  UID: selectedItem.UID,
                  Value: selectedItem.Value,
                  Type: CareEsioEntity.CareRecipient.EntityName,
                });
              }
            });
          }
        }
      } else if (appliesToType === "All") {
        appliesToValue = [
          {
            UID: null,
            Value:
              checklistTemplateEntity === RAFEntityName.Employee
                ? AppliesToTypeValue.AllEmployees
                : AppliesToTypeValue.ALLClients, //type
            Type:
              checklistTemplateEntity === RAFEntityName.Employee
                ? RAFEntityName.Employee
                : CareEsioEntity.CareRecipient.EntityName,
          },
        ];
      }

      value.AppliesTo = appliesToValue;
    } else if (value.Type === ChecklistTemplateItemType.Item) {
      const hasValidity = value.HasValidity;
      value.ProcessDeadlineInterval = hasValidity
        ? value.ProcessDeadlineInterval
        : null;
      value.ProcessDeadlineUnits = hasValidity
        ? value.ProcessDeadlineUnits
        : null;
    }

    const response = await saveChecklistTemplateItemAPI(value, url);
    hideProgress(progressDiv);
    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(response.entityId);
      }
    } else {
      showWarningToast(
        "Apologies, we're unable to save the record at the moment. Please try again later.!"
      );
    }
  };

  //delete item start
  const onClickDelete = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ChecklistTemplateItem`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    if (props.isChildItemsExist) {
      showWarningToast("Please delete child items before deleting this item.");
      deleteDialog.hide();
      return;
    }
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    let isDeleted = await DeleteRecord(props.objectUID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast(RAFActionMessage.RecordNotDeleted);
    }
  }
  //delete item end

  const getItemsContent = () => {
    return (
      <>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>("IsCompliance")}
            label="Is this document required for compliance?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>("IsRequired")}
            label="Is this document mandatory?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>("RequiresApproval")}
            label="Does this document require approval?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>("AllowUserUpload")}
            label="Can users upload this document?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>("HasExpiryDate")}
            label="Does this document require an expiry date?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        {getValidityContent()}
      </>
    );
  };

  const getAppliesToContent = () => {
    return (
      <>
        <div className="col-md-12">
          <ACDropdown
            field={propertyOf<ChecklistTemplateItemRow>("AppliesToType")}
            label="Option"
            allowAdd={false}
            formGroupClassName="mb-0"
          >
            <RAFChoiceOption
              label={
                checklistTemplateEntity === RAFEntityName.Employee
                  ? AppliesToTypeValue.AllEmployees
                  : AppliesToTypeValue.ALLClients
              }
              value={"All"}
            ></RAFChoiceOption>
            <RAFChoiceOption label="Select" value={"Select"}></RAFChoiceOption>
          </ACDropdown>
        </div>

        <Condition
          when={propertyOf<ChecklistTemplateItemRow>("AppliesToType")}
          is="Select"
        >
          {checklistTemplateEntity === RAFEntityName.Employee ? (
            <>
              {isNotEmptyArray(state.employeeLookup) && (
                <div className="col-md-12">
                  {getEmployeeMultiSelectDropdown()}
                </div>
              )}
              {isNotNullAndUndefined(state.teamsLookup) && (
                <div className="col-md-12">{getTeamMultiSelectDropdown()}</div>
              )}
            </>
          ) : (
            isNotEmptyArray(state.careRecipientLookup) && (
              <div className="col-md-12">
                {getCareRecipientMultiSelectDropdown()}
              </div>
            )
          )}
        </Condition>
      </>
    );
  };

  const getSectionLookUpContent = () => {
    const { checklistTemplateItemRow } = state;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    if (isNotNullAndUndefined(checklistTemplateItemRow.ChecklistTemplateUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(checklistTemplateItemRow.ChecklistTemplateUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<ChecklistTemplateItemRow>('ChecklistTemplateUID');
      customFilter.Rules.push(filter);

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(ChecklistTemplateItemType.Section);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ChecklistTemplateItemRow>('Type');
      customFilter.Rules.push(filter1);

      return (
        <div className="col-md-12">
          <RAFLookUpMUI<ChecklistTemplateItemRow>
            field="Parent"
            label="Section"
            placeholder="Select Section"
            url="ChecklistTemplateItem/Lookup"
            moduleName={CareEsioEntity.ChecklistTemplateItem.EntityName}
            customFilter={customFilter}
            showLabel
            closeToolTip={false}
            showFullList={false}
            required
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getTeamMultiSelectDropdown = () => {
    return (
      <RAFMultiSelectDropdown
        field={propertyOf<ChecklistTemplateItemRow>("SelectedTeams")}
        label="Team(s)"
        placeholder="Select Team(s)"
        showClearButton={false}
        allowFiltering
        uitype="checkbox"
        dropdownCheckboxDivClass="col-sm-6 col-md-4"
        formGroupClassName="mb-0"
      >
        {isNotEmptyArray(state.teamsLookup) &&
          state.teamsLookup.map((item) => {
            return (
              <RAFChoiceOption
                key={item.UID}
                value={item.UID}
                label={item.Value}
              />
            );
          })}
      </RAFMultiSelectDropdown>
    );
  };

  const getCareRecipientMultiSelectDropdown = () => {
    return (
      <RAFMultiSelectDropdown
        field={propertyOf<ChecklistTemplateItemRow>("SelectedClients")}
        label="Client(s)"
        placeholder="Select Client(s)"
        required
        showClearButton={false}
        allowFiltering
        uitype="checkbox"
        dropdownCheckboxDivClass="col-sm-6 col-md-4"
        formGroupClassName="mb-0"
      >
        {isNotEmptyArray(state.careRecipientLookup) &&
          state.careRecipientLookup.map((item) => {
            return (
              <RAFChoiceOption
                key={item.UID}
                value={item.UID}
                label={item.Value}
              />
            );
          })}
      </RAFMultiSelectDropdown>
    );
  };

  const getEmployeeMultiSelectDropdown = () => {
    return (
      <RAFMultiSelectDropdown
        field={propertyOf<ChecklistTemplateItemRow>("SelectedEmployees")}
        label="Employee(s)"
        placeholder="Select Employee(s)"
        showClearButton={false}
        allowFiltering
        uitype="checkbox"
        dropdownCheckboxDivClass="col-sm-6 col-md-4"
        formGroupClassName="mb-0"
      >
        {isNotEmptyArray(state.employeeLookup) &&
          state.employeeLookup.map((item) => {
            return (
              <RAFChoiceOption
                key={item.UID}
                value={item.UID}
                label={item.Value}
              />
            );
          })}
      </RAFMultiSelectDropdown>
    );
  };

  const onChangeHasValidity = (hasValidity: boolean) => {
    if (hasValidity === true) {
      setFormValue(rafForm, propertyOf<ChecklistTemplateItemRow>("ProcessDeadlineInterval"), 3);
      setFormValue(rafForm, propertyOf<ChecklistTemplateItemRow>("ProcessDeadlineUnits"), "Years");
    } else {
      setFormValue(rafForm, propertyOf<ChecklistTemplateItemRow>("ProcessDeadlineUnits"), null);
      setFormValue(rafForm, propertyOf<ChecklistTemplateItemRow>("ProcessDeadlineInterval"), null);
    }
  };

  const getValidityContent = () => {
    return (
      <>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistTemplateItemRow>(
              "HasValidity"
            )}
            label="Is there a validity period for this document?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeHasValidity}
          />
        </div>
        <Condition
          when={propertyOf<ChecklistTemplateItemRow>(
            "HasValidity"
          )}
          is={true}
        >
          <div className="col-md-12">
            <div className="row align-items-center">
              <div className="col-12 d-flex">
                <label className={"form-label required"}>
                  Enter the validity period
                </label>
              </div>
            </div>
            <div className="row gx-3 flex-nowrap">
              <div className="col-4">
                <RAFNumber
                  field={propertyOf<ChecklistTemplateItemRow>(
                    "ProcessDeadlineInterval"
                  )}
                  showLabel={false}
                  label="Validity"
                  formGroupClassName="mb-0"
                  showClearButton
                  minValue={0}
                  required
                />
              </div>
              <div className="col-8">
                <RAFRadioButtonList
                  field={propertyOf<ChecklistTemplateItemRow>(
                    "ProcessDeadlineUnits"
                  )}
                  showLabel={false}
                  label="Deadline Units"
                  required
                  uitype="customButton"
                >
                  {/* <RAFChoiceOption label="Business days" value="Business days" />
              <RAFChoiceOption label="Calendar days" value="Calendar days" />
              <RAFChoiceOption label="Weeks" value="Weeks" /> */}
                  <RAFChoiceOption label="Month(s)" value="Months" />
                  <RAFChoiceOption label="Year(s)" value="Years" />
                </RAFRadioButtonList>
              </div>
            </div>
          </div>
        </Condition>
      </>
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFForm
                type={ChecklistTemplateItemRow}
                initialValues={state.checklistTemplateItemRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitChecklistTemplateItem}
                className="h-100"
              >
                <div className="e-dlg-content-outer" id={outerDivId}>
                  <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                      <div className="col-md-12">
                        <ACTextBox
                          field={propertyOf<ChecklistTemplateItemRow>("Title")}
                          required
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <div className="col-md-12">
                        <RAFHtmlEditor
                          field={propertyOf<ChecklistTemplateItemRow>(
                            "Description"
                          )}
                          label="Description"
                          showLabel={true}
                          placeholder="Description"
                          rows={5}
                          fullHeight={false}
                          rowClassName="row g-0 gy-2"
                          useMentions={false}
                          mentionsField={null}
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <div className="col-md-12">
                        <ACDropdown
                          field={propertyOf<ChecklistTemplateItemRow>("Status")}
                          label="Status"
                          moduleName={moduleName}
                          showLabel={true}
                          allowAdd={false}
                          required
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <Condition
                        when={propertyOf<ChecklistTemplateItemRow>("Type")}
                        is={ChecklistTemplateItemType.Section}
                      >
                        {getAppliesToContent()}
                      </Condition>
                      <Condition
                        when={propertyOf<ChecklistTemplateItemRow>("Type")}
                        is={ChecklistTemplateItemType.Item}
                      >
                        {getSectionLookUpContent()}
                      </Condition>
                      <Condition
                        when={propertyOf<ChecklistTemplateItemRow>("Type")}
                        is={ChecklistTemplateItemType.Item}
                      >
                        {getItemsContent()}
                      </Condition>
                      {/* <div className="col-md-12">
                        <ACDropdown
                          field={propertyOf<ChecklistTemplateItemRow>("Type")}
                          label="Type"
                          moduleName={moduleName}
                          showLabel={true}
                          allowAdd={false}
                          required
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="e-dlg-footerContent">
                    <div className="w-100">
                      <div className="row gx-2 justify-content-between align-items-center">
                        <div className="col-auto">
                          <div className="row gx-2">
                            {isNotNullAndUndefined(props.objectUID) && (
                              <div className="col-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Delete}
                                  onClick={() => onClickDelete()}
                                  idString="DeleteContent"
                                  className="e-danger e-outline form-custom-button"
                                />
                              </div>
                            )}
                            {isNotNullAndUndefined(props.objectUID) && (
                              <Condition
                                when={propertyOf<ChecklistTemplateItemRow>("Type")}
                                is={ChecklistTemplateItemType.Item}
                              >
                                <div className="col-auto">
                                  <RAFButtonComponent
                                    type="button"
                                    isPrimary
                                    btnContent="Apply to already created"
                                    onClick={() => onClickPushBtn()}
                                    className="form-custom-button e-outline"
                                  />
                                </div>
                              </Condition>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                            className="form-custom-button"
                          />
                        </div> */}
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="CreateContent"
                            disabled={rafForm && rafForm.submitting}
                            className="form-custom-button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageChecklistTemplateItem);
