import {
  FileInfo,
  SelectedEventArgs,
  UploaderComponent,
} from "@syncfusion/ej2-react-inputs";
import * as R from "ramda";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFDatePickerMUI from "../../../RAFComponents/Inputs/RAFDatePickerMUI";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import { getFormValue, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFTabGroupBtn, { tabGroupBtnObject } from "../../../RAFComponents/Navigation/RAFTabGroupBtn";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { RetrieveRecord, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined, isNullOrUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ContentType, RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { ActivityRow } from "../../ActiveContacts/Activity/ActivityRow";
import { ContentLibraryRow } from "../../ActiveContacts/Document/Library/ContentLibraryRow";
import { getCompressedImage, getDocumentModuleNameByRelatedEntity } from "../../ActiveContacts/Document/Library/DocumentHelper";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";


interface IProps {
  isActive: boolean;
  mode: "create" | "updateVersion";

  selectedDocumentUID: string;

  relatedTo: string;
  relatedToUID: string;
  relatedToType: string;
  relatedEntity: string;

  deadlineInterval: number;
  deadlineUnits: string;

  hasExpiryDateRequired: boolean;

  onSave: () => void;
  onClose: () => void;

  createPermissionName: string;
  updatePermissionName: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  contentLibraryRow: ContentLibraryRow;
  fileUploadObj: any;
}

function ChecklistItemTransManageUploadDocumentFile({
  ...props
}: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;
  let rafRelatedDocumentForm: FormRenderProps | null;

  let fileUploadObj: UploaderComponent;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      contentLibraryRow: null,
      fileUploadObj: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [
    props.isActive,
    props.mode,
  ]);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true, noContent: true });
      let progressDiv = showProgress("#manageUploadDocumentDialog");
      const contentLibraryRow: ContentLibraryRow = await setContentLibraryData();
      hideProgress(progressDiv);
      setState({
        isLoading: false,
        contentLibraryRow,
        noContent: isNotNullAndUndefined(contentLibraryRow) ? false : true,
      });
    }
  };

  const setContentLibraryData = () => {
    return new Promise<ContentLibraryRow>(async (resolve) => {
      const { selectedDocumentUID } = props;
      if (isNotNullAndUndefined(selectedDocumentUID)) {
        const contentLibraryRow = await RetrieveRecord(selectedDocumentUID, RAFEntityName.ContentLibrary);
        if (isNotNullAndUndefined(contentLibraryRow) && isNotNullAndUndefined(contentLibraryRow.UID)) {
          contentLibraryRow['HasValidity'] = true;
          contentLibraryRow['DeadlineInterval'] = null;
          contentLibraryRow['DeadlineUnits'] = null;
          resolve(contentLibraryRow);
        } else {
          resolve(null);
        }
      } else {
        const contentLibraryRow = new ContentLibraryRow();
        contentLibraryRow['HasValidity'] = true;
        resolve(contentLibraryRow);

      }
    });
  };

  //submit document form start
  const onSubmitUploadDocument = async () => {
    let progressDiv = showProgress("#manageUploadDocumentDialog");
    const formData = await getDocumentSubmitFormData();

    const url = props.mode === 'updateVersion' ? 'ChecklistItemTrans/ReUpload' : 'ChecklistItemTrans/Upload';

    if (isNotNullAndUndefined(formData)) {
      repositoryActions
        .postDataAndGetResponse(
          url,
          formData,
          { ...props },
          ContentType.applicationFormUrlEncoded
        )
        .then(async (response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.documentUIDs) &&
            isNotNullAndUndefined(response.data.documentUIDs[0]) &&
            isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
          ) {
            showSuccessToast("File Uploaded Successfully");
            if (props.onSave) props.onSave();
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    } else {
      hideProgress(progressDiv);
      showWarningToast("Sorry something went wrong !");
    }

  };

  const getDocumentSubmitFormData = () => {
    return new Promise<FormData>(async (resolve) => {
      const selectedDocumentRow: ContentLibraryRow = R.clone(state.contentLibraryRow);
      const formData = new FormData();

      const fileData = isNotNullAndUndefined(fileUploadObj)
        ? fileUploadObj.getFilesData()
        : isNotNullAndUndefined(state.fileUploadObj)
          ? state.fileUploadObj
          : null;

      let selectedFileData = null;
      if (isNotEmptyArray(fileData)) {
        fileData.forEach((item) => {
          let file = item.rawFile;
          selectedFileData = file;
        });
      }

      const compressedImg = await getCompressedImage(R.clone(selectedFileData));

      formData.append("file", isNotNullAndUndefined(compressedImg) ? compressedImg : selectedFileData);

      const fileName = getFormValue(rafForm, propertyOf<ContentLibraryRow>('DisplayName'));
      const expiryDate: Date = getFormValue(rafForm, propertyOf<ChecklistItemTransRow>('ExpiryDate'));
      const issueDate: Date = getFormValue(rafForm, propertyOf<ChecklistItemTransRow>('IssueDate'));
      const message = getFormValue(rafForm, propertyOf<ActivityRow>('Message'));
      const mentions = getFormValue(rafForm, propertyOf<ActivityRow>('Mentions'));

      formData.append(propertyOf<ContentLibraryRow>('FileName'), fileName);
      formData.append(propertyOf<ContentLibraryRow>('DisplayName'), fileName);

      if (IsNotNullOrWhiteSpace(expiryDate)) {
        formData.append(propertyOf<ChecklistItemTransRow>('ExpiryDate'), expiryDate.toISOString());
      }

      if (IsNotNullOrWhiteSpace(issueDate)) {
        formData.append(propertyOf<ChecklistItemTransRow>('IssueDate'), issueDate.toISOString());
      }

      if (IsNotNullOrWhiteSpace(message)) {
        formData.append(propertyOf<ActivityRow>('Message'), message);
      }
      if (IsNotNullOrWhiteSpace(mentions)) {
        formData.append(propertyOf<ActivityRow>('Mentions'), mentions);
      }

      if (props.mode === 'create') {
        formData.append(propertyOf<ContentLibraryRow>('Entity'), getDocumentModuleNameByRelatedEntity(props.relatedEntity));

        if (isNotNullAndUndefined(props.relatedTo)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedTo'), props.relatedTo);
        }
        if (isNotNullAndUndefined(props.relatedToUID)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedToUID'), props.relatedToUID);
        }
        if (isNotNullAndUndefined(props.relatedToType)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedToType'), props.relatedToType);
        }
      } else if (props.mode === 'updateVersion') {
        formData.append(propertyOf<ContentLibraryRow>('UID'), selectedDocumentRow.UID);
        formData.append(propertyOf<ContentLibraryRow>('IsRevision'), "true");
        if (isNotNullAndUndefined(selectedDocumentRow.Entity)) {
          formData.append(propertyOf<ContentLibraryRow>('Entity'), getDocumentModuleNameByRelatedEntity(selectedDocumentRow.Entity));
        }

        if (isNotNullAndUndefined(props.relatedTo)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedTo'), props.relatedTo);
        }
        if (isNotNullAndUndefined(props.relatedToUID)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedToUID'), props.relatedToUID);
        }
        if (isNotNullAndUndefined(props.relatedToType)) {
          formData.append(propertyOf<ContentLibraryRow>('RelatedToType'), props.relatedToType);
        }
      }
      resolve(formData);
    });
  };
  //submit document form end

  //upload document start
  const onSubmitDocumentForm = () => {
    let allowFormSubmit = true;
    if (
      isNotNullAndUndefined(rafRelatedDocumentForm) &&
      rafRelatedDocumentForm.invalid === true
    ) {
      allowFormSubmit = false;
      rafRelatedDocumentForm.form.submit();
    }
    if (allowFormSubmit === true) onClickUploadDocument();
  };

  const onClickUploadDocument = async () => {
    let allowSubmit = true;
    let fileData = fileUploadObj.getFilesData();

    if (props.mode === "updateVersion") {
      if (fileData.length !== 1) {
        allowSubmit = false;
        showWarningToast("Please select one document to upload.");
        return;
      }
    } else {
      if (fileData.length > 0) {
        fileData.forEach((item) => {
          if (item.size === 0) {
            allowSubmit = false;
            showWarningToast(item.name + " File size is too small!");
          }
        });
      } else {
        allowSubmit = false;
        showWarningToast("Please select alteast one document to upload.");
      }
    }

    if (allowSubmit) {
      onSubmitUploadDocument();
    }
  };
  //upload document end

  function onFileSelected(args: SelectedEventArgs): void {
    let existingFiles: FileInfo[] = fileUploadObj.getFilesData();
    for (let i = 0; i < args.filesData.length; i++) {
      for (const j of existingFiles) {
        if (!isNullOrUndefined(args.filesData[i])) {
          if (j.name === args.filesData[i].name) {
            args.filesData.splice(i, 1);
          }
        }
      }
    }
    existingFiles = existingFiles.concat(args.filesData);
    // args.modifiedFilesData = existingFiles;
    // args.isModified = true;

    let displayName;
    if (isNotEmptyArray(existingFiles)) {
      existingFiles.forEach((item) => {
        if (item.size !== 0) {
          displayName = item.name;
        }
      });
    }
    updateDisplayName(displayName);
  }

  const updateDisplayName = (displayName: string) => {
    setFormValue(rafForm, "DisplayName", displayName);
  };

  const onChangeIssueDate = (value) => {
    if (isNotNullAndUndefined(value)) {
      const issueDate = new Date(value);
      const deadlineUnits: 'Months' | 'Years' = props.deadlineUnits as 'Months' | 'Years';
      const deadlineInterval = props.deadlineInterval;
      if (isNotNullAndUndefined(deadlineInterval) && isNotNullAndUndefined(deadlineUnits)) {
        if (deadlineUnits === 'Months') {
          const expiryDate = new Date(issueDate);
          expiryDate.setMonth(expiryDate.getMonth() + deadlineInterval);
          setFormValue(rafForm, propertyOf<ChecklistItemTransRow>('ExpiryDate'), expiryDate);
        } else if (deadlineUnits === 'Years') {
          const expiryDate = new Date(issueDate);
          expiryDate.setFullYear(expiryDate.getFullYear() + deadlineInterval);
          setFormValue(rafForm, propertyOf<ChecklistItemTransRow>('ExpiryDate'), expiryDate);
        }
      }
    }
  };

  const getExpiryDatePickerContent = () => {
    const { deadlineInterval, deadlineUnits } = props;
    const expiryDateDisplayLabel =
      isNotNullAndUndefined(deadlineInterval) && isNotNullAndUndefined(deadlineUnits) ? `Expiry Date (${deadlineInterval}-${deadlineUnits})` : 'Expiry Date';
    return (
      <div className="col-md-12">
        <RAFDatePickerMUI
          field={propertyOf<ChecklistItemTransRow>('ExpiryDate')}
          label={expiryDateDisplayLabel}
          //labelRightSection={labelRightSection(propertyOf<ChecklistItemTransRow>('ExpiryDate'))}
          required={props.hasExpiryDateRequired === true ? true : false}
        />
      </div>
    );
  };

  const getReviewDatePickerContent = () => {
    return (
      <div className="col-md-12"   >
        <ACDatePicker
          field={propertyOf<ChecklistItemTransRow>('ReviewDate')}
          label="Review Date"
        />
      </div>
    );
  };

  //grp start

  const onSelectGroubBtn = (item: tabGroupBtnObject) => {
    if (isNotNullAndUndefined(item)) {
      if (item.id === propertyOf<ChecklistItemTransRow>("ExpiryDate")) {
        setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("HasValidity"), false);
      } else {
        setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("HasValidity"), true);
      }
    }
  };

  const labelRightSection = (selectedItem) => {
    const groupBtnItems = [
      {
        iconClass: "fas fa-timer",
        id: propertyOf<ChecklistItemTransRow>("ValidityPeriod"),
        title: "Validity Period",
      },
      {
        iconClass: "fa-solid fa-hourglass-half",
        id: propertyOf<ChecklistItemTransRow>("ExpiryDate"),
        title: "Expiry Date",
      },
    ];
    return (
      <div className="ms-auto">
        <RAFTabGroupBtn
          items={groupBtnItems}
          mode="TextOnly"
          selectedItem={selectedItem}
          onSelect={onSelectGroubBtn}
          uiType="RadioBtn"
        />
      </div>
    );
  };
  //grp btn end

  const getValidityExpiryDate = () => {
    return (
      <>
        {getExpiryDatePickerContent()}
        {getReviewDatePickerContent()}
      </>
    );
  };

  const getCheckListTransAdditionalInputFields = () => {
    if (props.relatedToType === CareEsioEntity.ChecklistItemTrans.EntityName) {
      return (
        <div className="row">
          <div className="col-md-12">
            <ACDatePicker
              field={propertyOf<ChecklistItemTransRow>('IssueDate')}
              label="Issue Date"
              onChanged={onChangeIssueDate}
            />
          </div>
          {getValidityExpiryDate()}
          <div className="col-md-12">
            <RAFTextArea
              field={propertyOf<ActivityRow>('Message')}
              label="Add Document Information"
              showLabel
              placeholder="Enter document number, certification authority, and any additional notes."
              rows={5}
              onInputs={(e) => { }}
              onChanged={(e) => { }}
              useMentions
              mentionsField={propertyOf<ActivityRow>('Mentions')}
            />
          </div>
        </div>
      );
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        const {
          contentLibraryRow,
        } = state;
        return (
          <>
            <RAFEntityProvider moduleName={RAFEntityName.ContentLibrary}>
              <RAFAttributeRelatedListProvider
                moduleName={RAFEntityName.ContentLibrary}
              >
                <RAFForm
                  type={ContentLibraryRow}
                  submitOnEnterKey={false}
                  initialValues={contentLibraryRow}
                  formRef={(g) => (rafForm = g)}
                  layout={RAFLayout.TwoColumnLayout}
                  onSubmit={() => onSubmitDocumentForm()}
                  className="h-100"
                >
                  <div className="e-dlg-content-outer">
                    <div className={`e-dlg-body-content pt-2 p-3 m-0`}>
                      <div
                        className="row align-items-center mb-3"
                        id="attachmentDiv"
                      >
                        <div className="col-12 d-flex">
                          <label className="form-label required">
                            Choose a file
                          </label>
                        </div>
                        <div className="col-12 d-flex">
                          <div className="w-100">
                            <UploaderComponent
                              id="fileUpload"
                              type="file"
                              ref={(dialog) => (fileUploadObj = dialog)}
                              minFileSize={1}
                              multiple={false}
                              selected={
                                onFileSelected.bind(this)
                              }
                              removing={() => {
                                updateDisplayName(null);
                              }}
                            ></UploaderComponent>
                          </div>
                        </div>
                      </div>
                      {getCheckListTransAdditionalInputFields()}
                    </div>
                    <div className={`e-dlg-footerContent`}>
                      <div className="w-100">
                        <div className="row gx-3">
                          <div className="col">
                            <RAFButtonComponent action={RAFButtonConstant.Cancel}
                              className="w-100 new_style"
                              onClick={() => props.onClose()}
                            ></RAFButtonComponent>
                          </div>
                          <RAFPermissionRender
                            permissionName={
                              props.mode === "create"
                                ? props.createPermissionName ?? PermissionConstants.DocumentCreate
                                : props.updatePermissionName ?? PermissionConstants.DocumentUpdate
                            }
                          >
                            <div className="col">
                              <RAFButtonComponent action={RAFButtonConstant.Upload}
                                isPrimary
                                className="w-100 new_style"
                                type="submit"
                                showIcon={false}
                              ></RAFButtonComponent>
                            </div>
                          </RAFPermissionRender>
                        </div>
                      </div>
                    </div>
                  </div>
                </RAFForm>
              </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
          </>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState
              title="This item is either deleted or You do not have sufficient privileges to view this item."
            />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}


export default React.memo(ChecklistItemTransManageUploadDocumentFile);
