import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";
import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";
import { ILookupRow } from "../../../../RAFComponents/models/CompositeTypes/ILookupRow";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";

export enum ChecklistTemplateItemStatus {
    Draft = 'Draft',
    Active = 'Active',
    InActive = 'In Active',
    Archived = 'Archived'
}

export enum ChecklistTemplateItemType {
    Section = 'Section',
    Item = 'Item'
}

export class IChecklistTemplateItemRow {
    UID?: string;
    Title?: string;
    Description?: string;
    Type?: string;
    Status?: string;
    IsRequired?: boolean;
    IsCompliance?: boolean;
    RequiresApproval?: boolean;
    AllowUserUpload?: boolean;

    ProcessDeadlineUnits?: string;
    ProcessDeadlineInterval?: number;
    ProcessDeadlineCalculation?: string;

    HasExpiryDate?: boolean;
    HasValidity?: boolean;
    AppliesTo?: RAFRelatedToRow[];
    ParentUID?: string;
    Parent?: string;
    ChecklistTemplateUID?: string;
    ChecklistTemplate?: string;
    ChecklistTemplateItemID?: string;
    ChecklistTemplateItemIDNumber?: number;

    Portal?: string;
    PortalUID?: string;


    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class ChecklistTemplateItemRow extends IChecklistTemplateItemRow implements RAFEntityBase, ILookupRow {
    AppliesToType?: string;
    SelectedClients?: string[];
    SelectedEmployees?: string[];
    SelectedTeams?: string[];

    getClassName?() {
        return CareEsioEntity.ChecklistTemplateItem.EntityName;
    }
    getIdField?(): string {
        return propertyOf<ChecklistTemplateItemRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "ChecklistTemplateItem/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "ChecklistTemplateItem/Save";
    }
    getGroupByUrl?(): string {
        return "ChecklistTemplateItem/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "ChecklistTemplateItem/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
