import { DialogUtility } from "@syncfusion/ej2-popups";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";

import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFNumber from "../../../RAFComponents/Inputs/RAFNumber";
import RAFRadioButtonList from "../../../RAFComponents/Inputs/RAFRadioButtonList";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { DeleteRecord, RAFActionMessage, RetrieveRecord, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { IDialogProps, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { ChecklistTemplateItemRow } from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemRow";
import { saveChecklistItemTransAPI } from "./ChecklistItemTransHelper";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";

interface IProps {
  initialValues?: ChecklistItemTransRow;
  onDelete?: () => void;
  objectUID?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  checklistItemTransRow: ChecklistItemTransRow;
}

function ManageChecklistItemTrans({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;

  const outerDivId = `manageupdate_checklistItemTrans_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      checklistItemTransRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [
        checklistItemTransRow,
      ] = await Promise.all([
        getIntitailChecklistItemTransRow(),
      ]);

      if (isNotNullAndUndefined(checklistItemTransRow)) {
        setState({
          isLoading: false,
          noContent: false,
          checklistItemTransRow,
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailChecklistItemTransRow = () => {
    return new Promise<ChecklistItemTransRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const checklistItemTransRow: ChecklistItemTransRow =
          await RetrieveRecord(props.objectUID, moduleName);
        if (
          isNotNullAndUndefined(checklistItemTransRow) &&
          isNotNullAndUndefined(checklistItemTransRow.UID)
        ) {
          resolve(checklistItemTransRow);
        } else {
          resolve(null);
        }
      } else {
        if (props.initialValues) {
          resolve(props.initialValues);
        } else {
          let initialObject = new ChecklistItemTransRow();

          resolve(initialObject);
        }
      }
    });
  };

  const onSubmitChecklistItemTrans = async (
    formValue: ChecklistItemTransRow
  ) => {
    let progressDiv = showProgress(`#${outerDivId}`);

    const value: ChecklistItemTransRow = R.clone(formValue);

    const response = await saveChecklistItemTransAPI(value);
    hideProgress(progressDiv);
    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(response.entityId);
      }
    } else {
      showWarningToast(
        "Apologies, we're unable to save the record at the moment. Please try again later.!"
      );
    }
  };

  //delete item start
  const onClickDelete = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete Compliance Document`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    let isDeleted = await DeleteRecord(props.objectUID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast(RAFActionMessage.RecordNotDeleted);
    }
  }
  //delete item end

  const getItemsContent = () => {
    return (
      <>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>("IsCompliance")}
            label="Is this document required for compliance?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>("IsRequired")}
            label="Is this document mandatory?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>("RequiresApproval")}
            label="Does this document require approval?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>("AllowUserUpload")}
            label="Can users upload this document?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>("HasExpiryDate")}
            label="Does this document require an expiry date?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
          />
        </div>
        {getValidityContent()}
      </>
    );
  };

  const onChangeHasValidity = (hasValidity: boolean) => {
    if (hasValidity === true) {
      setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("DeadlineInterval"), 3);
      setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("DeadlineUnits"), "Years");
    } else {
      setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("DeadlineUnits"), null);
      setFormValue(rafForm, propertyOf<ChecklistItemTransRow>("DeadlineInterval"), null);
    }
  };

  const getValidityContent = () => {
    return (
      <>
        <div className="col-md-12">
          <RAFCheckBox
            field={propertyOf<ChecklistItemTransRow>(
              "HasValidity"
            )}
            label="Is there a validity period for this document?"
            showLabel={true}
            uitype={"switch"}
            labelPosition="left"
            labelClassName="BpStpExtSet__customContent col"
            inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeHasValidity}
          />
        </div>
        <Condition
          when={propertyOf<ChecklistItemTransRow>(
            "HasValidity"
          )}
          is={true}
        >
          <div className="col-md-12">
            <div className="row align-items-center">
              <div className="col-12 d-flex">
                <label className={"form-label required"}>
                  Enter the validity period
                </label>
              </div>
            </div>
            <div className="row gx-3 flex-nowrap">
              <div className="col-4">
                <RAFNumber
                  field={propertyOf<ChecklistItemTransRow>(
                    "DeadlineInterval"
                  )}
                  showLabel={false}
                  label="Validity"
                  formGroupClassName="mb-0"
                  showClearButton
                  minValue={0}
                  required
                />
              </div>
              <div className="col-8">
                <RAFRadioButtonList
                  field={propertyOf<ChecklistItemTransRow>(
                    "DeadlineUnits"
                  )}
                  showLabel={false}
                  label="Deadline Units"
                  required
                  uitype="customButton"
                >
                  {/* <RAFChoiceOption label="Business days" value="Business days" />
              <RAFChoiceOption label="Calendar days" value="Calendar days" />
              <RAFChoiceOption label="Weeks" value="Weeks" /> */}
                  <RAFChoiceOption label="Month(s)" value="Months" />
                  <RAFChoiceOption label="Year(s)" value="Years" />
                </RAFRadioButtonList>
              </div>
            </div>
          </div>
        </Condition>
      </>
    );
  };

  const categoryLookupContent = () => {
    const { checklistItemTransRow } = state;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(checklistItemTransRow.ChecklistTemplateUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(checklistItemTransRow.ChecklistTemplateUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<ChecklistTemplateItemRow>("ChecklistTemplateUID");
      customFilter.Rules.push(filter);
    }

    let parentFilter: RAFCustomFilter = {};
    parentFilter.Operator = RAFCustomOperator.IsNull;
    parentFilter.Field = propertyOf<ChecklistTemplateItemRow>("ParentUID");
    customFilter.Rules.push(parentFilter);

    return (
      <RAFLookUpMUI
        field={propertyOf<ChecklistItemTransRow>("Category")}
        label="Category"
        url={'ChecklistTemplateItem/LookUp'}
        moduleName={CareEsioEntity.ChecklistTemplateItem.EntityName}
        showLabel={true}
        formGroupClassName="mb-0"
        customFilter={customFilter}
      />
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFForm
                type={ChecklistItemTransRow}
                initialValues={state.checklistItemTransRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitChecklistItemTrans}
                className="h-100"
              >
                <div className="e-dlg-content-outer" id={outerDivId}>
                  <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                      <div className="col-md-12">
                        <ACTextBox
                          field={propertyOf<ChecklistItemTransRow>("Title")}
                          required
                          formGroupClassName="mb-0"
                        />
                      </div>
                      {false && (
                        <Field
                          name={propertyOf<ChecklistItemTransRow>('HasExpiryDate')}>
                          {({ input, meta }) => {
                            const hasExpiryDate = input.value;
                            return (
                              <div className="col-md-12"
                                key={hasExpiryDate.toString()}
                              >
                                <ACDatePicker
                                  field={propertyOf<ChecklistItemTransRow>("ExpiryDate")}
                                  label="Expiry Date"
                                  showLabel={true}
                                  formGroupClassName="mb-0"
                                  required={hasExpiryDate === true ? true : false}
                                />
                              </div>
                            );
                          }}
                        </Field>
                      )}
                      <div className="col-md-12">
                        {categoryLookupContent()}
                      </div>
                      <div className="col-md-12">
                        <RAFHtmlEditor
                          field={propertyOf<ChecklistItemTransRow>(
                            "Description"
                          )}
                          label="Description"
                          showLabel={true}
                          placeholder="Description"
                          rows={5}
                          fullHeight={false}
                          rowClassName="row g-0 gy-2"
                          useMentions={false}
                          mentionsField={null}
                          formGroupClassName="mb-0"
                        />
                      </div>
                      {false && (
                        <div className="col-md-12">
                          <ACDropdown
                            field={propertyOf<ChecklistItemTransRow>("Status")}
                            label="Status"
                            moduleName={moduleName}
                            showLabel={true}
                            allowAdd={false}
                            required
                            formGroupClassName="mb-0"
                          />
                        </div>
                      )}
                      {getItemsContent()}
                    </div>
                  </div>
                  <div className="e-dlg-footerContent ">
                    <div className="w-100">
                      <div className="row gx-2">
                        {isNotNullAndUndefined(props.objectUID) && (
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Delete}
                              onClick={() => onClickDelete()}
                              idString="DeleteContent"
                              className="e-danger e-outline form-custom-button"
                            />
                          </div>
                        )}
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                            className="form-custom-button"
                          />
                        </div>
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="CreateContent"
                            disabled={rafForm && rafForm.submitting}
                            className="form-custom-button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageChecklistItemTrans);
